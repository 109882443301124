<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <q-form ref="editForm">
        <c-card title="예약 정보 상세" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline>
              <c-btn
                v-if="editable"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="data"
                :mappingType="saveType"
                label="예약하기"
                icon="save"
                @beforeAction="saveData"
                @btnCallback="saveCallback" 
              />
              <c-btn label="예약취소" v-if="editable && popupParam.reserveId" icon="remove" @btnClicked="deleteData" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <!-- 병원 -->
              <c-select
                :required="true"
                :comboItems="comboItems"
                type="edit"
                itemText="codeName"
                itemValue="code"
                name="hospitalId"
                label="병원"
                v-model="data.hospitalId"
                @datachange="datachangeHosptial"
              ></c-select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-text
                :disabled="true"                  
                label="병원위치"
                name="hospitalLocation"
                v-model="data.hospitalLocation"
              >
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-field
                :required="true"
                label='예약자'
                :editable="editable"
                name="reserveUserId"
                v-model="data.reserveUserId"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-plant
                :required="true"
                :disabled="disable"
                :editable="editable"
                type="edit"
                name="plantCd"
                v-model="data.plantCd"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-datepicker
                label='예약시간'
                :minuteStep="10"
                :editable="editable"
                type="time"
                name="reserveTime"
                v-model="data.reserveTime"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
              <c-text
                label='LBLREMARK'
                :editable="editable"
                name="reserveName"
                v-model="data.reserveName"
              />
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'checkup-reserve-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        reserveDate: '',
        reserveDay: '',
        reserveMonth: '',
        reserveYear: '',
        reserveId: '',
        hospitalIds: null,
      }),
    },
  },
  data() {
    return {
      comboItems:[],
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      data: {
        plantCd: null,
        reserveId: '',
        hospitalId: null,
        heaCheckupPlanId: '',
        reserveName: '',
        reserveTime: '',
        reserveUserId: '',
        reserveUserName: '',
        reserveDate: '',
        reserveDay: '',
        reserveMonth: '',
        reserveYear: '',
      },
      editable: true,
      disable: false,
      saveUrl: 'transactionConfig.hea.checkup.reserve.insert.url',
      saveType: 'POST',
      isSave: false,
      hospitalList: [],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;

      this.data.reserveUserId = this.$store.getters.user.userId;
      
      this.getUrl = selectConfig.hea.checkup.reserve.get.url;
      this.insertUrl = transactionConfig.hea.checkup.reserve.insert.url;
      this.updateUrl = transactionConfig.hea.checkup.reserve.update.url;
      this.deleteUrl = transactionConfig.hea.checkup.reserve.delete.url;

      this.getDetail();
      this.setHospital();
    },
    setHospital() {
      this.$http.url = selectConfig.hea.hospital.list.url;
      this.$http.type = 'GET';
      this.$http.param = {
        useFlag: 'Y',
      };
      this.$http.request((_result) => {
        if (this.popupParam.hospitalIds) {
          this.hospitalList = this.popupParam.hospitalIds.split(',')
        }
        this.$_.forEach(_result.data, _item => {
          if (this.hospitalList.indexOf(_item.hospitalId) > -1) {
            this.comboItems.push({
              code: _item.hospitalId,
              codeName: _item.hospitalName,
              hospitalLocation: _item.hospitalLocation,
            })
          }
        })

        this.$_.forEach
      },);
    },
    getDetail() {
      if (this.popupParam.reserveId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.reserveId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data

        },);
      }
    },
    saveData() {
      if (this.popupParam.reserveId) {
        this.data.chgUserId = this.$store.getters.user.userId
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.data.regUserId = this.$store.getters.user.userId
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
        this.$set(this.data, 'reserveDate', this.popupParam.reserveDate)
        this.$set(this.data, 'reserveDay', this.popupParam.reserveDay)
        this.$set(this.data, 'reserveMonth', this.popupParam.reserveMonth)
        this.$set(this.data, 'reserveYear', this.popupParam.reserveYear)
        this.$set(this.data, 'heaCheckupPlanId', this.popupParam.heaCheckupPlanId)
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.popupParam.reserveId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('closePopup')
    },
    datachangeHosptial(data) {
      let idx = this.$_.findIndex(this.comboItems, {code: data.value })
      if (idx > -1) {
        this.data.hospitalLocation = this.comboItems[idx].hospitalLocation;
      }
    },
    deleteData() {
      this.$http.url = this.$format(this.deleteUrl, this.popupParam.reserveId);
      this.$http.type = 'DELETE';
      this.$http.request((_result) => {
        if (_result.data > 0) {
          this.$q.notify({
            color: 'positive',
            html: true,
            message: '예약취소 완료',
            multiLine: true,
            timeout: 5000
          });
        }
        this.$emit('closePopup')
      },);
    }
  },
};
</script>
