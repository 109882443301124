var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "q-form",
            { ref: "editForm" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "예약 정보 상세" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.saveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.data,
                                  mappingType: _vm.saveType,
                                  label: "예약하기",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveData,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                          _vm.editable && _vm.popupParam.reserveId
                            ? _c("c-btn", {
                                attrs: { label: "예약취소", icon: "remove" },
                                on: { btnClicked: _vm.deleteData },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            required: true,
                            comboItems: _vm.comboItems,
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "hospitalId",
                            label: "병원",
                          },
                          on: { datachange: _vm.datachangeHosptial },
                          model: {
                            value: _vm.data.hospitalId,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "hospitalId", $$v)
                            },
                            expression: "data.hospitalId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            disabled: true,
                            label: "병원위치",
                            name: "hospitalLocation",
                          },
                          model: {
                            value: _vm.data.hospitalLocation,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "hospitalLocation", $$v)
                            },
                            expression: "data.hospitalLocation",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-field", {
                          attrs: {
                            required: true,
                            label: "예약자",
                            editable: _vm.editable,
                            name: "reserveUserId",
                          },
                          model: {
                            value: _vm.data.reserveUserId,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "reserveUserId", $$v)
                            },
                            expression: "data.reserveUserId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: true,
                            disabled: _vm.disable,
                            editable: _vm.editable,
                            type: "edit",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.data.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "plantCd", $$v)
                            },
                            expression: "data.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            label: "예약시간",
                            minuteStep: 10,
                            editable: _vm.editable,
                            type: "time",
                            name: "reserveTime",
                          },
                          model: {
                            value: _vm.data.reserveTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "reserveTime", $$v)
                            },
                            expression: "data.reserveTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            label: "LBLREMARK",
                            editable: _vm.editable,
                            name: "reserveName",
                          },
                          model: {
                            value: _vm.data.reserveName,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "reserveName", $$v)
                            },
                            expression: "data.reserveName",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }